import React from "react";
import { Link } from "react-router-dom";
import Drawer from "./Drawer";
const classes = require('./Header.module.scss');
const logo = require('../../assets/images/wcgLogo.png');


const header = () => {
  return (
    <header className={classes.Header}>
      <nav className={[classes.bigRow, "uk-navbar-container uk-margin"].join(' ')} data-uk-navbar>
        <div className="uk-navbar-center">
          <div className={[ classes.navLeft, "uk-navbar-center-left"].join(' ')}><div>
            <ul className="uk-navbar-nav">
              <li className={classes.navItem}>
                <Drawer />
              </li>
              <li className={[classes.navItem, classes.phone].join(' ')}><a href="tel: +12515976651">(251) 597-6651</a></li>
            </ul>
          </div></div>
          
          <Link to="/" className={[classes.logo, "uk-navbar-item uk-logo"].join(' ')}><img src={logo} alt="Wharf Catering Group Logo" className={[classes.imgResponsive, classes.ilLogo].join(' ')} /></Link>
          
          <div className={[ classes.navRight, "uk-navbar-center-right"].join(' ')}><div>
            <ul className="uk-navbar-nav">
              <li className={[classes.navItem, classes.email].join(' ')}><a href="mailto:sales@wharfcateringgroup.com">sales@wharfcateringgroup.com</a></li>
              
              <li className={[classes.navItem, classes.social, classes.fb].join(' ')}><a href="https://www.facebook.com/wharfcatering" target="_blank" rel="noopener noreferrer">
                <span className={classes.SocialIcon} aria-label="Wharf Restaurant Group Facebook Link" uk-icon="icon: facebook"></span>
              </a></li>

              <li className={[classes.navItem, classes.social, classes.insta].join(' ')}><a href="https://www.instagram.com/wharfcateringgroup/" target="_blank" rel="noopener noreferrer">
                <span aria-label="Wharf Restaurant Group Instagram Link" uk-icon="icon: instagram"></span>
              </a></li>

              <li className={[classes.navItem, classes.social, classes.twitter].join(' ')}><a href="https://www.twitter.com/WharfCatering/" target="_blank" rel="noopener noreferrer">
                <span aria-label="Wharf Restaurant Group Instagram Link" uk-icon="icon: twitter"></span>
              </a></li>
            </ul>
          </div></div>
        </div>
      </nav>
    </header>
  );
}

export default header;