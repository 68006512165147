import React from "react";
// import classes from "/MenuPage.module.scss";
import weddingImg from "../../assets/images/menuWeddingReception2.jpg";
import corpLunchImg from "../../assets/images/menuCorporateLunch.jpg";
import luncheonImg from "../../assets/images/menuLuncheon.jpg";
import tacoImg from "../../assets/images/menuTacoBar.jpg";
import cocktailDinnerImg from "../../assets/images/menuCocktailDinner.jpg";
const weddingMenu = (
  <div>
    <h3>Extras: Cups, Dinner Plates, Dessert Plates, &amp; Rolled Flatware</h3>
    <h4>Passed Hor D’Oeuvres</h4>
    <p>Mini Crab cakes with Housemade Sriracha Tartar Sauce</p>
    <p>Caprese Tartlets</p>

    <hr />
    <h4>Entrées</h4>
    <p>Pan Seared Salmon with Beurre’ Blanc Sauce &amp; Served with Broccolini &amp; Truffle Risotto &amp; Cremini Mushrooms</p>
    <p>Bone In Pork Chop Served with Pancetta Pea Mac &amp; Cheese, Asparagus, &amp; Peach Aioli</p>
    
    <hr />
    <h4>Desserts</h4>
    <p>White Chocolate Mousse Tartlets Topped with Fresh Berries</p>
    <p>Brownies with or without Nuts</p>
    
    <hr />
    <h4>Drinks</h4>
    <p>Sweet/Unsweet Tea &amp; Water</p>
  </div>
);
const corpLunchMenu = (
  <div>
    <h3>Extras: Cups, Dinner Plates, Dessert Plates, &amp; Rolled Flatware</h3>
    <h4>Hor D’Oeuvres</h4>
    <p>Caprese Tartlets</p>
    
    <hr/>
    <h4>Main Course</h4>
    <p>Smoked Pork Tenderloin</p>
    <p>Potato Gratin with Sharp White Cheddar Cheese &amp; Caramelized Onion Peach Chutney</p>
    <p>Glazed Carrots &amp; Dinner Rolls</p>
    
    <hr/>
    <h4>Desserts</h4>
    <p>Cinnamon Roll Bread Pudding… Candied Pecans &amp; Whiskey Cream</p>


    <hr/>
    <h4>Drinks</h4>
    <p>Sweet/Unsweet Tea &amp; Water </p>
  </div>
);
const luncheonMenu = (
  <div>
    <h3>Extras: Cups, Dinner Plates, Dessert Plates, &amp; Rolled Flatware</h3>
    <h4>Hors d’oeuvres</h4>
    <p>Caprese Tartlets</p>
    <p>BBQ Bacon Wrapped Shrimp (2 each)</p>
    <p>Stuffed Mushrooms</p>
    <p>Sriracha &amp; Cream Cheese Wontons</p>
    <p>Salmon Terrine (2 each)</p>
    <p>Prime Rib Sliders with Arugula &amp; Horseradish Sauce</p>
    
    <hr/>
    <h4>Desserts</h4>
    <p>Blondie’s</p>
    <p>Brownies</p>

    <hr/>
    <h4>Drinks</h4>
    <p>Sweet/Unsweet Tea &amp; Water </p>
  </div>
);
const tacoMenu = (
  <div>
    <h3>Extras: Cups, Dinner Plates, Dessert Plates, &amp; Rolled Flatware</h3>
    <h4>Appetizers</h4>
    <p>Housemade Chips with Queso &amp; Salsa</p>
    <p>Sriracha &amp; Cream Cheese Wontons</p>
    <hr />
    <h4>Taco Bar</h4>
    <p>Classic Ground Beef Taco Topped with Pico De Gallo, Shredded Romaine, Cheddar &amp; Monterey Jack Cheese, &amp; Sour Cream</p>
    <p>Fried Chicken Taco Topped with Crunchy Mexican Slaw, Monterey Jack &amp; Cheddar Cheese &amp; Chipotle Aioli</p>
    <p>Shrimp Taco Topped with Skillet Corn, Black Bean Salsa, Cotija Cheese, &amp; Cilantro Cream</p>
    <hr />
    <h4>Dessert</h4>
    <p>Chocolate Cake Bites Filled with Peanut Butter Frosting</p>
    <p>Vanilla Cake Bites Filled with Strawberry Frosting</p>
    <hr />
    <h4>Drinks</h4>
    <p>Sweet/Unsweet Tea &amp; Water </p>

  </div>
);
const cocktailDinnerMenu = (
  <div>
    <h3>Extras: Cups, Dinner Plates, Dessert Plates, &amp; Rolled Flatware</h3>
    <h4>Cheese Plate</h4>
    <p>Gorgonzola, Honey Drizzle Brie, Smoked Gouda, &amp; Manchego Cheese</p>
    <p>Served with Fresh Fruit &amp; Crackers</p>
    <hr/>
    <h4>Cocktail Dinner</h4>
    <p>BBQ Bacon Wrapped Shrimp with Cherry BBQ Sauce (2 Per Person)</p>
    <p>Prosciutto Wrapped Grilled Asparagus</p>
    <p>Beef &amp; Vegetable Skewers</p>
    <p>Roast Beef Sliders with Arugula &amp; Horseradish</p>
    <hr/>
    <h4>Desserts</h4>
    <p>Assorted Cheesecake Bites</p>
    <p>Assorted Cookies</p>
    <hr/>
    <h4>Drinks</h4>
    <p>Sweet/Unsweet Tea &amp; Water </p>
  </div>
);

const sampleMenus = [
  {id:1, title:"Wedding Reception", image: [weddingImg], menu: [weddingMenu], link:"wedding-reception"},
  {id:2, title:"Corporate Lunch", image: [corpLunchImg], menu: [corpLunchMenu], link:"corporate-lunch"},
  {id:3, title:"Luncheon Catering", image: [luncheonImg], menu: [luncheonMenu], link:"luncheon-catering"},
  {id:4, title:"Taco Party Bar", image: [tacoImg], menu: [tacoMenu], link:"taco-bar"},
  {id:5, title:"Cocktail Dinner", image: [cocktailDinnerImg], menu: [cocktailDinnerMenu], link:"cocktail-dinner"},
];
export default sampleMenus;