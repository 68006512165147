import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from "react-router-dom";
const classes = require('./Header.module.scss');

function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={[classes.list, classes.SideNavList].join(' ')}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes.closeIcon}><ClearIcon /></div>
      <ul className={classes.SideNav}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/menus">Sample Menus</Link></li>
        <li><Link to="/about">About Us</Link></li>
        <li><Link to="/gallery">Gallery</Link></li>
        <li className={classes.quoteNav}><button className={classes.modalBtn} type="button" data-uk-toggle="target: #request-quote">Get a Quote</button></li>
      </ul>
      
      <ul className={classes.sideContact}>
        <hr />
        <li className={[classes.navItem, classes.phone].join(' ')}><a href="tel: +12515976651">(251) 597-6651</a></li>
        <li className={[classes.navItem, classes.email].join(' ')}><a href="mailto:sales@wharfcateringgroup.com">sales@wharfcateringgroup.com</a></li>
      </ul>
    </div>
  );

  return (
    <div className={classes.NavDrawer}>
      <Button onClick={toggleDrawer('left', true)}>
        <span aria-label="All Green Alabama Medical Mobile Navigation Link" uk-icon="icon: menu" className={classes.mobileNavBtn}></span>
        {/* <MenuIcon /> */}
      </Button>
     
      <Drawer anchor="left" open={state.left} onClose={toggleDrawer('left', false)} className={classes.cNavDrawer}>
        {sideList('left')}
      </Drawer>
    </div>
  );
}

export default TemporaryDrawer;