import React, {Component} from 'react';
const classes = require('./FooterAlt.module.scss');

class FooterAlt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: ''
    };
  }

  render() {
    return (
      <footer className={classes.FooterAlt}>
        <div className={classes.Split}>
          <div className={classes.inside}>
            <div className={classes.mailHlf}>
              <h4>Join Our Mailing List!</h4>
              <hr className={classes.clearBorderWhite} />
              <p>Interested in finding out about our wine dinners, specials, events, or to just stay in the know? We promise we won't overshare or sell your information.</p>
              <div className={classes.MCsignup}>
                <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css" />
                <div id="mc_embed_signup" className={classes.MCinner}>
                  {/* <form action="https://ginnylanebargrill.us10.list-manage.com/subscribe/post?u=fe0c421eb8ae1acbc885e39d3&amp;id=303b0e0335" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank"> */}
                  <form action="https://ginnylanebargrill.us10.list-manage.com/subscribe/post" method="POST" target="_blank" noValidate>
                    <input type="hidden" name="u" value="fe0c421eb8ae1acbc885e39d3"/>
                    <input type="hidden" name="id" value="303b0e0335"/>
                    <input 
                      type="email" 
                      name="EMAIL" 
                      id="MERGE0"
                      className={classes.MCemail} 
                      placeholder="Email Address" 
                      value={this.state.emailValue} 
                      onChange={ (e)=>{this.setState({emailValue: e.target.value});} } 
                      required 
                    /> 
                    <div className={classes.clearfix}/>
                    <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={classes.wcgLink} />
                    <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_fe0c421eb8ae1acbc885e39d3_303b0e0335" value="" /></div>
                  </form>
                </div>
              </div>  
            </div>
            
            <div className={classes.contactHlf}>
              <div className={classes.cBox}>
                <h4>Contact Details</h4>
                <p><b>Mailing Address</b><br/>
                  Wharf Restaurant Group<br/>
                  Attn: Catering Department<br/>
                  4673 Wharf Pkwy W, Suite B<br/>Orange Beach, Al 36561
                </p>
                
                <p><b>Email Address</b><br/><a href="mailto:sales@wharfcateringgroup.com">sales@wharfcateringgroup.com</a></p>
                <p><b>Phone Number</b><br/><a href="tel: +12515976651">(251) 597-6651</a></p>
                
                <ul className={classes.Social}>
                  <li><a href="https://www.facebook.com/wharfcatering" target="_blank" rel="noopener noreferrer">
                    <span className={classes.SocialIcon} aria-label="The Wharf Catering Group Facebook Link" uk-icon="icon: facebook"></span>
                  </a></li>

                  <li><a href="https://www.instagram.com/wharfcateringgroup/" target="_blank" rel="noopener noreferrer">
                    <span aria-label="The Wharf Catering Group Instagram Link" uk-icon="icon: instagram"></span>
                  </a></li>

                  <li><a href="https://twitter.com/WharfCatering" target="_blank" rel="noopener noreferrer">
                    <span aria-label="The Wharf Catering Group Twitter Link" uk-icon="icon: twitter"></span>
                  </a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default FooterAlt;