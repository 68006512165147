import React, {Component} from 'react';
import { Route, Switch } from "react-router-dom";
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";
import Header from "./components/Header/Header";
import FooterAlt from "./components/FooterAlt/FooterAlt";
import QuoteModal from "./components/QuoteModal/QuoteModal";
import Home from "./hoc/Home/Home";
import Gallery from "./hoc/Gallery/Gallery";
import Menus from "./hoc/Menus/Menus";
import About from "./hoc/About/About";
import sampleMenus from "./hoc/MenuPage/MenuLoop";
import MenuPage from "./hoc/MenuPage/MenuPage";
const classes = require('./App.module.scss');


class App extends Component {
  render() {
    const menuLoop = sampleMenus.map((item) =>
      <Route
        path={"/" + [item.link]}
        key={item.id}
        render={props => <MenuPage {...props} id={item.id} link={item.link} PageTitle={item.title} image={item.image} menu={item.menu} />}
      />
    );

    let routes = (
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/menus" component={Menus} />
        <Route path="/about" component={About} />
        {menuLoop}
      </Switch>
    );
    
    return (
      <div className={classes.wcgroupWrap}>
        <Header />
        <main className={classes.Content}>{routes}</main>
        <FooterAlt />
        <QuoteModal />
        <ScrollUpButton
          ContainerClassName="ToTopBtn"
          TransitionClassName="MyOverRideTransitionedClass"
          style={{ fill: 'rgb(255,255,255)', color: '#008AAB', backgroundColor: '#116c78', width: '20px', padding: '0px 5px' }}
          EasingType='linear'
        />
      </div>
    );
  }
}

export default App;
