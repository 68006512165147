import React, {Component} from "react";
import classes from './QuoteModal.module.scss';
// import { useForm, ValidationError } from '@formspree/react';


export default class Form extends Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
      <div id="request-quote" className={classes.quoteModal} data-uk-modal>
        <div className={[["uk-modal-dialog"], classes.customModal].join(' ')}>
          <button className="uk-modal-close-default" type="button" data-uk-close></button>
          <div className={[classes.ukmb, "uk-modal-body"].join(' ')}>
            <div className={[classes.modalHead, "uk-modal-header"].join(' ')}>
              <h4>Request a Quote</h4>
              <p>*All fields are required</p>
            </div>

            <form
              className={classes.careerForm}
              onSubmit={this.submitForm}
              action="https://formspree.io/f/mrgjovgz"
              method="POST"
            >
              <input type="hidden" name="_subject" id="email-subject" value="Quote Request Submission from Website"></input>

              <div className={classes.formGroupMid}>
                <p><span>*</span> Full Name:</p>
                <input type="text" name="name" />
              </div>
              
              <div className={classes.formGroupLine}>
                <p><span>*</span> Email Address:</p>
                <input type="email" name="_replyto" />
              </div>
              
              <div className={classes.formGroupLine}>
                <p><span>*</span> Phone Number:</p>
                <input type="text" name="phone" />
              </div>
              
              <div className={classes.formGroupLine}>
                <p><span>*</span> Date of Event:</p>
                <input type="date" name="date" className={classes.inputDate} />
              </div>
              
              <div className={classes.formGroupLine}>
                <p><span>*</span> Guest Count:</p>
                <input type="text" name="guests" />
              </div>
              
              <div className={classes.formGroupMid}>
                <p><span>*</span> Message:</p>
                <textarea name="message"></textarea>
              </div>

              {/* <div className={classes.medDef}> */}
              {status === "SUCCESS" ? <div className={classes.successSub}><p>Thank you for your interest in our catering services! We will review your information, and someone from our team will contact you soon.</p></div> : <div className={[classes.formGroupSubmit, "uk-text-right"].join(' ')}><button className={classes.wcgLink}>Submit</button></div>}
              {status === "ERROR" && <p className={classes.errorSub}>Your request contains here was an error. Please check your information, and try again.</p>}
              {/* </div> */}
            </form>
          </div>
        </div>
      </div>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}