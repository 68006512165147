import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const classes = require('./Menus.module.scss');
// const corpLunchMenu = require('../../Corporate-Lunch-Menu.pdf');
// const luncheonMenu = require('../../Luncheon-Catering-Menu.pdf');
// const weddingReceptionMenu = require('../../Wedding-Reception-Menu.pdf');
// const tacoBarMenu = require('../../Taco-Party-Bar-Menu.pdf');
// const cocktailDinnerMenu = require('../../Cocktail-Dinner-Menu.pdf');

const menus = () => {
  return (
    <div className={classes.Menus}>
      <Helmet>
        <title>Baldwin County Catering Company | Sample Menus | Wharf Catering Group</title>
        <meta name="description" content="Take a look at some of the sample menus from The Wharf Catering Group." />
      </Helmet>

      <div className={classes.MenuIntro}>
        <div className={classes.insideNrw}>
          <h1>Sample Menus</h1>
          <p>These are just a few examples of catering menus we have used for past events.
            We work closely with each of our clients to create a custom menu for their event that brings their vision to life.
            <br/>
            We can accommodate guest lists of all sizes.
          </p>
          <button className={classes.wcgLink} type="button" data-uk-toggle="target: #request-quote">Get a Quote</button>
        </div>
      </div>
      
      <div className={classes.MenuSec1}>
        <div className={classes.medDef}>
          <div className={classes.menuList}>
            <Link to="/corporate-lunch" className={[classes.menuListItem, classes.corp].join(' ')}>
              <h4>Corporate Lunch</h4>
            </Link>
            
            <Link to="/luncheon-catering" className={[classes.menuListItem, classes.luncheon].join(' ')}>
              <h4>Luncheon Catering</h4>
            </Link>
            
            <Link to="/wedding-reception" className={[classes.menuListItem, classes.wedding].join(' ')}>
              <h4>Wedding Reception</h4>
            </Link>
            
            <Link to="/cocktail-dinner" className={[classes.menuListItem, classes.cocktail].join(' ')}>
              <h4>Cocktail Dinner</h4>
            </Link>
            
            <Link to="/taco-bar" className={[classes.menuListItem, classes.taco].join(' ')}>
              <h4>Taco Party Bar</h4>
            </Link>
            
            <button className={[classes.menuListItem, classes.quote].join(' ')} type="button" data-uk-toggle="target: #request-quote">
              <h4>Click here to contact us for a quote, and start planning your custom menu!</h4>
            </button>
          </div>
          
        </div>
      </div>
    </div>
  );
}
export default menus;