import React from "react";
import { Helmet } from "react-helmet";
const classes = require('./Gallery.module.scss');
const photo1 = require('../../assets/images/gallery/cacMuffins.jpg');
const photo2 = require('../../assets/images/gallery/cacSalads.jpg');
const photo3 = require('../../assets/images/gallery/cacBuffet2.jpg');
const photo4 = require('../../assets/images/gallery/wcgMimosas.jpg');
const photo5 = require('../../assets/images/gallery/cacShrimpGrits.jpg');
const photo6 = require('../../assets/images/gallery/cacBeverage.jpg');
const photo7 = require('../../assets/images/gallery/cacKids.jpg');
const photo8 = require('../../assets/images/gallery/wcgAntipasto.jpg');
const photo9 = require('../../assets/images/gallery/wcgPatio.jpg');
const photo10 = require('../../assets/images/gallery/wcgBloodyMary.jpg');
const photo11 = require('../../assets/images/gallery/cacFoodTable.jpg');
const photo12 = require('../../assets/images/gallery/wcgBoil.jpg');
const photo13 = require('../../assets/images/gallery/wcgPuffs.jpg');
const photo14 = require('../../assets/images/gallery/wcgSeating.jpg');
const photo15 = require('../../assets/images/gallery/wcgPops.jpg');
// const photo16 = require('../../assets/images/gallery/wcgBread.jpg');


const photos = [
  {id: 1, image: [photo1], alt: 'Wharf Catering Group food display at wedding', class: 'reg'},
  {id: 2, image: [photo2], alt: 'Wharf Catering Group salad display', class: 'tallRight'},
  {id: 3, image: [photo13], alt: 'Wharf Catering Group dessert tray', class: 'reg'},
  {id: 4, image: [photo4], alt: 'Mimosa bar by The Wharf Catering Group', class: 'thin'},
  {id: 5, image: [photo5], alt: 'Shrimp and grits display at wedding in Orange Beach', class: 'long'},
  {id: 6, image: [photo6], alt: 'Beverage display at catered event in Orange Beach', class: 'hlf'},
  {id: 7, image: [photo7], alt: 'Kids food table by The Wharf Catering Group', class: 'hlf'},
  {id: 8, image: [photo8], alt: 'Wedding food display in Orange Beach, Al', class: 'thin3'},
  {id: 9, image: [photo9], alt: 'Rehearsal dinner on the patio of Ginny Lane at The Wharf', class: 'hlf'},
  {id: 10, image: [photo10], alt: 'Bloody Mary bar at wedding', class: 'thin3'},
  {id: 11, image: [photo11], alt: 'Wharf Catering Group buffet table', class: 'hlf'},
  {id: 12, image: [photo12], alt: 'Shrimp boil catered by Wharf Catering Group', class: 'hlf'},
  {id: 13, image: [photo14], alt: 'Seating chart for rehearsal dinner at Villaggio Grille', class: 'tall'},
  {id: 14, image: [photo15], alt: 'Cake pops from Wharf Catering Group', class: 'reg'},
  {id: 16, image: [photo3], alt: 'Wedding buffet in Orange Beach', class: 'reg'},
];


const imgList = photos.map((photo) =>
  // <div key={photo.id} className={classes[photo.class]}> data-caption={photo.image}
    <a key={photo.id} className={[classes.galleryPhoto, classes[photo.class]].join(' ')} href={photo.image}> 
      <img src={photo.image} alt={photo.alt} className={classes.imgResponsive} />
    </a>
  // </div> 
);

const gallery = () => {
  return (
    <div className={classes.Gallery}>
      <Helmet>
        <title>Wharf Catering Group Gallery</title>
        <meta name="description" content="Take a look through some of The Wharf Catering Group's favorite photos from past events." />
      </Helmet>

      <div className={classes.intro}>
      <div className={classes.insideNrw}>
        <h1>Photo Gallery</h1>
        <p>Take a look at some of our favorite photos from events that we've catered.<br/> Contact us for a quote, and put our team to work for you.</p>
        <button className={classes.wcgLink} type="button" data-uk-toggle="target: #request-quote">Get a Quote</button>
        </div>
      </div>
      
      <div className={classes.medDef} data-uk-lightbox="animation: slide;">
        <div className={classes.newGall}>
          {imgList}
        </div>
      </div>
    </div>
  );
}

export default gallery;