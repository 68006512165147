import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const classes = require('./Home.module.scss');
const wrg = require('../../assets/images/wrg-logo.png');
const vg = require('../../assets/images/vg-logo.png');
const gl = require('../../assets/images/gl-logo.png');
const yoho = require('../../assets/images/yoho-logo.png');
const marker = require('../../assets/images/marker-logo.png');
const symbol = require('../../assets/images/wcg-symbol.png');
const diningVG = require('../../assets/images/diningVG.jpg');
const diningGL = require('../../assets/images/diningGL.jpg');
const diningYoho = require('../../assets/images/diningYoho.jpg');
const diningMM = require('../../assets/images/diningMM2.jpg');

const home = () => {
  return (
    <div className={classes.Home}>
      <Helmet>
        <title>Event Catering in Baldwin County | Wharf Catering Group</title>
        <meta name="description" content="The Wharf Catering Group offers catering services for private parties, corporate banquets, weddings, and other events of all sizes." />
      </Helmet>
    
      <div className={classes.heroMenu}>
        {/* <div className={[classes.hmItem, classes.quote].join(' ')}>
          <button className={classes.hmText} type="button" data-uk-toggle="target: #request-quote"> */}
        <button className={[classes.hmItem, classes.quote].join(' ')} type="button" data-uk-toggle="target: #request-quote">
          <div className={classes.hmText}>
          Get a Quote
          </div>
        </button>
        
        <Link to="/menus" className={[classes.hmItem, classes.menus].join(' ')}>
          {/* <Link to="/menus" className={classes.hmText}> */}
          <div className={classes.hmText}>
          Sample Menus
          </div>
        </Link>
        
        <Link to="/about" className={[classes.hmItem, classes.about].join(' ')}>
          {/* <Link to="/menus" className={classes.hmText}> */}
          <div className={classes.hmText}>
          About Us
          </div>
        </Link>
        
        <Link to="/gallery" className={[classes.hmItem, classes.gallery].join(' ')}>
          {/* <Link to="/gallery" className={classes.hmText}> */}
          <div className={classes.hmText}>
          Gallery
          </div>
        </Link>
      </div>
     
    
      <div className={classes.homeWhoV2b}>
        <div className={classes.insideNrw}>
          <div className={classes.intro}>
            <h1>The Wharf Catering Group</h1>
            <p><b>The Wharf Restaurant Group</b>, made up of Ginny Lane Bar & Grill, Villaggio Grille, and YoHo Rum & Tacos, has been 
              proudly serving customers at The Wharf in Orange Beach since 2006. As our restaurants have grown over the years, so have our 
              catering needs. <br/>This led us to start <b>The Wharf Catering Group.</b></p>

            <p className={classes.alt}><b>The Wharf Catering Group</b> offers catering services from all three of our restaurants, 
              for events both on and off-site -- private parties, corporate banquets, weddings, and other events of all sizes.</p>
              
            <div className={classes.spacer1} />
            <a href="#restaurants" className={classes.wcgLink} data-uk-scroll>See Our Restaurants</a>
            <div className={classes.clearfixBtn} />
          </div>
        </div>
           
        <div className={classes.medDef}>
          <div className={classes.left}>  
            <div className={classes.logoLine}>
              <div className={classes.insideXl}>
                <img src={wrg} className={[classes.imgResponsive, classes.wrgLogo].join(' ')} alt="Wharf Restaurant Group Logo" />
                <img src={gl} className={[classes.imgResponsive, classes.restLogo].join(' ')} alt="Ginny Lane Logo" />
                <img src={vg} className={[classes.imgResponsive, classes.restLogo, classes.rlVG].join(' ')} alt="Villaggio Grille Logo" />
                <img src={yoho} className={[classes.imgResponsive, classes.restLogo, classes.rlYoho].join(' ')} alt="YoHo Rum and Tacos Logo" />
                <img src={marker} className={[classes.imgResponsive, classes.restLogo, classes.marker].join(' ')} alt="Mile Marker 158 Dockside Logo" />
              </div>
            </div>
          </div>
          
          <div className={classes.right}>
            <p>We will create a specialized menu for your event, and provide our amazing and professional catering staff 
              to ensure that your guests enjoy a wonderful dining experience, and leave completely satisfied.</p>
              
            <p>From intimate celebrations to large presentations and wedding receptions, we serve parties (and appetites) of all sizes!</p>
            
            <div className={classes.spacer1} />
            <a href="#restaurants" className={[classes.wcgLink, classes.centerx].join(' ')} data-uk-scroll>Learn More</a>
            <div className={classes.clearfixBtn} />
          </div>
          
        </div>
      </div>
      
      
      <div className={classes.homeQuote}>
        <div className={classes.med2Def}>
          <img src={symbol} className={classes.imgResponsive} alt="Wcg logo symbol" />
          <div className={classes.text}>
            <h3>Request a Quote</h3>
            <p>Give us the details about your event, including a brief description, date, estimated number of guests, and any specific menu requests, 
              and we will contact you with some great ideas!</p>
            
            <button className={classes.modalBtn} type="button" data-uk-toggle="target: #request-quote">Get Started</button>
          </div>
        </div>
      </div>
    
      <div id="restaurants" className={classes.homeLocations}>
        <div className={classes.medDef}>
          <div className={classes.borderTitle2x}>
            <h3>Our Restaurants</h3>
          </div>
        
          <div className={[classes.rBox, classes.gl].join(' ')}>
            <img src={diningGL} className={[classes.imgResponsive, classes.diningRoom].join(' ')} alt="Ginny Lane Entrance" />
            <div className={classes.cTextBox}>
              <div className={classes.cImage}><img src={gl} className={classes.imgResponsive} alt="Ginny Lane Logo" /></div>
              <p>Our first restaurant - comfortable and upscale, with a menu that covers a variety of inventive salads, sandwiches, pasta, seafood, and steaks.
                Enjoy waterfront dining with family and friends.
              </p>
              <a href="http://ginnylanebargrill.com/"  target="_blank" rel="noopener noreferrer" className={classes.wcgLink}>Visit Website</a>
            </div>
          </div>
          
          <div className={[classes.rBox, classes.vg].join(' ')}>
            <img src={diningVG} className={[classes.imgResponsive, classes.diningRoom].join(' ')} alt="Villaggio Grille Entrance" />
            <div className={classes.cTextBox}>
              <div className={classes.cImage}><img src={vg} className={classes.imgResponsive} alt="Villaggio Grille Logo" /></div>
              <p>Villaggio Grille offers the highest quality food, and exceptional service. 
                A white tablecloth, Mediterranean-Italian restaurant with an award-winning wine list, and a patio to dine al fresco and enjoy the sights!
              </p>
              <a href="http://www.villaggiogrille.com/" target="_blank" rel="noopener noreferrer" className={classes.wcgLink}>Visit Website</a>
            </div>
          </div>
          
          <div className={[classes.rBox, classes.yoho].join(' ')}>
            <img src={diningYoho} className={[classes.imgResponsive, classes.diningRoom].join(' ')} alt="Yoho Rum and Tacos Entrance" />
            <div className={classes.cTextBox}>
              <div className={classes.cImage}><img src={yoho} className={classes.imgResponsive} alt="Yoho Rum and Tacos Logo" /></div>
              <p>YoHo Rum and Tacos offers beachy flavor and fun, with its waterfront dining, signature cocktails, and a menu that offers some of the most delicious tacos you'll ever taste.</p>
              <a href="https://www.yohorumandtacos.com/" target="_blank" rel="noopener noreferrer" className={classes.wcgLink}>Visit Website</a>
            </div>
          </div>
        
          <div className={[classes.rBox, classes.marker].join(' ')}>
            <img src={diningMM} className={[classes.imgResponsive, classes.diningRoom].join(' ')} alt="Mile Marker 158 Dockside" />
            <div className={classes.cTextBox}>
              <div className={classes.cImage}><img src={marker} className={[classes.imgResponsive, classes.centerBlock].join(' ')} alt="Mile Marker 158 Dockside Logo" /></div>
              <p>Mile Marker 158 Dockside offers casual culinary cuisine, with a beautiful view of the water and The Wharf Marina. 
                Both indoor and outdoor seating are available.</p>
              <a href="https://milemarker158.com/" target="_blank" rel="noopener noreferrer" className={classes.wcgLink}>Visit Website</a>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

export default home;