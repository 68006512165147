import React from "react";
import { Helmet } from "react-helmet";
const classes = require('./About.module.scss');
const hc1 = require('../../assets/images/bride-buffet.jpg');

const hc2 = require('../../assets/images/homeCustom2.jpg');
const symbol = require('../../assets/images/wcg-symbol.png');


const about = () => {
  return (
    <div className={classes.About}>
      <Helmet>
        <title>Orange Beach Catering Services | Wharf Catering Group</title>
        <meta name="description" content="The Wharf Catering Group offers specialized menus and a professional catering staff to create a wonderful dining experience for you and your guests." />
      </Helmet>
      <div className={classes.abCustom}>
        <div className={classes.med2Def}>
          <div className={classes.left}>
            <div className={classes.inner}>
              <h1>Because it <br className={classes.brLg}/>needs <br className={classes.brTb}/> to be<br className={classes.brLg}/> <span>special.</span></h1>
              
              <h4>Specialized Menus</h4>
              <p>Each occasion begins with a custom menu, and ends with a precious memory of delicious cuisine and exceptional service.</p>
              <h4>Custom Dining Experience</h4>
              <p>With a range of unique rooms and flexible floor plans, our private dining team is eager to create a custom dining 
                experience, tailored for your event.
              </p>
              <h4>Catering for Every Event</h4>
              <p>From intimate celebrations to large presentations and wedding receptions, we serve parties (and appetites) of all sizes!</p>
            
              <button className={classes.wcgLink} type="button" data-uk-toggle="target: #request-quote">Get a Quote</button>
            </div>
          </div>
          
          <div className={classes.right}>
            <img src={hc1} className={[classes.imgResponsive, classes.endxImg].join(' ')} alt="Bride getting food from wedding buffet" />
            <img src={hc2} className={[classes.imgResponsive, classes.midxImg].join(' ')} alt="Rehearsal dinner setup on Ginny Lane patio at The Wharf" />
          </div>
        </div>
      </div>
    
      <div className={classes.abInfo}>
        <div className={classes.abiIntro}>
          <div className={classes.text}><h3>Full-service catering<br/>
            <span>&amp; why you <b>need</b> it</span>
          </h3></div>
        </div>
        
        <div className={classes.medDef}>
          <div className={classes.abiBlock1}>
            <h4>Event size matters</h4> {/* Why */}
            <p>When it comes to events and juggling budgets, it’s common to be tempted to bite off more than you can chew. 
            This is particularly true when it comes to keeping food catering costs to a minimum. 
            Do we really need staff? What about DIY options and food trucks? It depends on the size of your event.</p>
            <p>If you’re planning a small gathering, then you can certainly wow your guests with a food catering delivery and buffet-style spread. 
            These caterers simply deliver the food, and you do the rest (armed with biodegradable disposables to keep cleaning and breakages 
            to a minimum.)</p>
          </div>
          
          <div className={classes.abiBlock2}>
            <h4>Schedule a Consultation</h4>
            <p>However, when you’re planning a larger or more formal event, particularly a wedding or corporate party, 
            things can unravel very quickly without the right support.</p>
            <p>You have to consider timing, and you’ll need to have 
            the right flatware, linens, glassware, and yes, staff. This is where full-service catering comes into its own.</p>
          </div>

          <div className={classes.abiBlock3}>
            <h4>Teamwork makes the dream work</h4>
            <p>One key difference between regular, off-premise catering and full-service catering is staff. To the outside observer, 
            a seamless wedding runs like clockwork thanks to a team of professionals behind the scenes.</p>
            <p>Full service means you’ll have bartenders, kitchen staff, and the front of house and servers.
            Everyone in place to set up tables, manage portions, bus away dirty plates and glasses, and pack everything away at the end.</p>
            <p>Overseeing the catering team is your event captain. 
            He or she works alongside your coordinator, but generally concentrates on nailing timings, maintaining quality control, 
            making sure glasses are charged and plates piled high, and keeping an eye on plates that need clearing.</p>
          </div>
        </div>
      </div>
    
      <div className={classes.homeQuote}>
        <div className={classes.med2Def}>
          <img src={symbol} className={classes.imgResponsive} alt="Catering setup at wedding in Orange Beach" />
          <div className={classes.text}>
            <h3>Request a Quote</h3>
            <p>Give us the details about your event, including a brief description, date, estimated number of guests, and any specific menu requests, 
              and we will contact you with some great ideas!</p>
            
            <button className={classes.modalBtn} type="button" data-uk-toggle="target: #request-quote">Get Started</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default about;