import React from "react";
import { Helmet } from "react-helmet";
const classes = require('./MenuPage.module.scss');

const menuPage = (props) => {
  // {props.ImageAlt ? props.ImageAlt : "Wharf Catering Group sample menus for Gulf Coast events" }
  return (
    <div className={[classes.MenuPage, classes[props.link]].join(' ')}>
      <Helmet>
        <title>{props.PageTitle} | Wharf Catering Group</title>
        <meta name="description" content="Take a look at some of the sample menus from The Wharf Catering Group." />
      </Helmet>

      <div className={classes.MenuHeader} style={{ backgroundImage: "url(" + props.image + ")", backgroundPosition: props.bgPosition }}>
        <div className={classes.insideNrw}>
          <h1>{props.PageTitle}
            <br/><span className={classes.span2}>Sample Menu</span><br/>
          </h1>
          <button className={classes.wcgLink} type="button" data-uk-toggle="target: #request-quote">Get a Quote</button>
        </div>
      </div>
      
      <div className={[classes.MenuSec, classes[props.addClass]].join(' ')}>
        <div className={classes[props.container]}>
          {props.menu}
        </div>
      </div>
    </div>
  );
}
menuPage.defaultProps = {
  container:"insideNrw",
  bgPosition:"center center"
};
export default menuPage;